<script setup lang="ts">
const props = defineProps({
  username: {
    type: String,
    required: true,
  },
  image: {
    type: String,
  },
  imageClass: {
    type: String,
    default:
      "bg-blue-100 w-10 h-10 flex justify-center items-center rounded-md overflow-hidden",
  },
  textClass: {
    type: String,
    default: "bg-blue-100 w-10 h-10 flex justify-center items-center rounded-md text-blue-500 text-md",
  },
});

const Initials: any = () => {
  const name = props.username.split(" ");
  return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ""}`;
};
</script>

<template>
  <div :class="imageClass">
    <img
      :src="image"
      alt="Image preview"
      class="object-cover w-full h-full"
      v-if="image"
    />

    <span :class="textClass" v-else>{{ Initials() }}</span>
  </div>
</template>